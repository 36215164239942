
import { Component, Vue } from 'vue-property-decorator';
import Schedule from '@/components/Public/Schedule.vue';

@Component({
  components: {
    Schedule,
  },
})
export default class Home extends Vue {}
